import { render, staticRenderFns } from "./empty.vue?vue&type=template&id=c3f1a10e&scoped=true"
import script from "./empty.vue?vue&type=script&lang=ts"
export * from "./empty.vue?vue&type=script&lang=ts"
import style0 from "~/assets/styles/common.scss?vue&type=style&index=0&prod&lang=scss&external"
import style1 from "./empty.vue?vue&type=style&index=1&id=c3f1a10e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3f1a10e",
  null
  
)

export default component.exports